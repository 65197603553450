<template>
  <div>	  
    <a-card title="销售单详情">
      <a-button slot="extra" type="primary" style="margin-right: 8px;" ghost v-print="'#printContent'"> <a-icon type="printer" />打印</a-button>
      <a-button slot="extra" type="primary" ghost @click="() => { this.$router.go(-1); }"> <a-icon type="left" />返回</a-button>
      <section id="">
		  <div class="apps" id="printContent">
		  	<div class="top">
		  		<div class="title">
		  			<strong style="font-size: 25px;">赣州中大装饰材料有限公司（韶关办事处）</strong><span style="font-size:22px;margin-left: 1.25rem;">送货单</span>
		  		</div>
		  		<div style="height: 25px;line-height: 25px;font-size: 16px;">
		  			<table style="width: 100%;" border="0">
		  				<tr border="1">
		  					<td style="width: 30%;text-align: left;">日期：{{ info.handle_time }}</td>
		  					<td style="width: 30%;text-align: left;">电话：{{ info.client_phone }}</td>
		  					<td style="width: 40%;text-align: left;">单号：{{ info.number }}</td>
		  				</tr>
		  				<tr>
		  					<td style="width: 30%;text-align: left;">客户：{{ info.client_name }}</td>
		  					<td style="width: 30%;text-align: left;">联系：{{ info.client_contact }}</td>
		  					<td style="width: 40%;text-align: left;">地址：{{ info.client_address }}</td>
		  				</tr>
		  			</table>
		  		</div>
		  		<div style="margin-top: 40px;">
		  			<a-table bordered
		  			  rowKey="id"
		  			  size="default"
		  			  :columns="columns"
		  			  :data-source="info.sales_goods_items"
					  :scroll="false"
		  			  :pagination="false" />
		  			<div style="margin-top: 20px;">
		  				<table style="width: 100%;" border="0">
<!-- 		  					<tr border="1">
		  						<td style="width: 25%;text-align: left;">合计金额：</td>
		  						<td style="width: 25%;text-align: left;"></td>
		  						<td style="width: 25%;text-align: left;">合计数量:</td>
		  						<td style="width: 25%;text-align: left;"></td>
		  					</tr> -->
		  					<tr style="height: 35px;font-size: 16px;" border="0">
		  						<td style="width: 25%;text-align: left;">制单人：{{ info.creator_name }}</td>
		  						<td style="width: 25%;text-align: left;">仓管：</td>
		  						<td style="width: 25%;text-align: left;">送货人：</td>
		  						<td style="width: 25%;text-align: left;">收货人：</td>
		  					</tr>
		  				</table>
		  				<div style="font-size: 16px;">							
		  					<div>本凭证系客户未付款先提货结算清单，收货人签字即生效，本公司随时结账，不得以任何理由拒付。</div>
		  					<span>第一联：存根（白）</span> 
		  					<span>第二联：结算（红）</span>
		  					<span>第三联：仓库（蓝）</span>
		  					<span>第四联：客户（黄）</span>
		  				</div>
		  			</div>
		  		</div>
		  	</div>
		  </div>
        <!-- <a-spin :spinning="loading">
          <img id="barcode" style="float: right" />
          <a-descriptions bordered>
            <a-descriptions-item label="销售编号">
              {{ info.number }}
            </a-descriptions-item>
            <a-descriptions-item label="销售单号">
              {{ info.sales_order }}
            </a-descriptions-item>
            <a-descriptions-item label="客户">
              {{ info.client_name }}
            </a-descriptions-item>
            <a-descriptions-item label="仓库">
              {{ info.warehouse_name }}
            </a-descriptions-item>
            <a-descriptions-item label="经手人">
              {{ info.handler_name }}
            </a-descriptions-item>
            <a-descriptions-item label="处理日期">
              {{ info.handle_time }}
            </a-descriptions-item>
            <a-descriptions-item label="其他费用">
              {{ info.other_amount }}
            </a-descriptions-item>
            <a-descriptions-item label="备注">
              {{ info.remark }}
            </a-descriptions-item>
          </a-descriptions>
          <a-divider orientation="left" style="margin-top: 30px;">结算账户信息</a-divider>
          <a-table
            rowKey="id"
            size="middle"
            :columns="columnsAccount"
            :data-source="info.sales_account_items"
            :pagination="false" />
          <a-divider orientation="left" style="margin-top: 30px;">产品信息</a-divider>
          <a-table
            rowKey="id"
            size="middle"
            :columns="columns"
            :data-source="info.sales_goods_items"
            :pagination="false" />
        </a-spin> -->
      </section>
    </a-card>
  </div>
</template>

<script>
  import { saleOrderDetail } from '@/api/sale'
  import JsBarcode from 'jsbarcode'
  import NP from 'number-precision'

export default {
    data() {
      return {
        loading: false,
        materialLoading: false,
        receiptOrder: undefined,
        info: {},
        columns: [
          {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            width: 70,
			align:'left',
            customRender: (value, item, index) => {
              return item.isTotal ? '合计' : (index + 1)
            },
          },
		 //  {
		 //    title: '编号',
		 //    dataIndex: 'goods_number',
		 //    key: 'goods_number',
			// width: 150,
			// align:'left',
		 //  },
		  {
		    title: '规格',
		    dataIndex: 'spec',
		    key: 'spec',
			width: 150,
			align:'left',
		  },
          {
            title: '颜色',
            dataIndex: 'goods_name',
            key: 'goods_name',
			width: 150,
			align:'left',
          },
		  {
		    title: '板面',
		    dataIndex: 'broad',
		    key: 'broad',
			width: 100,
			align:'left',
		  },
		  {
		    title: '型号',
		    dataIndex: 'types',
		    key: 'types',
			width: 100,
			align:'left',
		  },          
          {
            title: '单位',
            dataIndex: 'unit_name',
            key: 'unit_name',
            width: 80,
			align:'left',
          },
          {
            title: '数量',
            dataIndex: 'sales_quantity',
            key: 'sales_quantity',
            width: 80,
			align:'left',
          },
          {
            title: '单价',
            dataIndex: 'sales_price',
            key: 'sales_price',
            width: 80,
			align:'left',
          },
          {
            title: '金额',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            width: 100,
			align:'left',
            customRender: (value, item) => {
              if (item.isTotal) return value;
              value = NP.times(item.sales_quantity, item.sales_price);
              return item.id ? NP.round(value, 2) : ''
            },
          },
		  {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
			align:'left',
			width:120
          }
        ],
        columnsAccount: [
          {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            width: 45,
            customRender: (value, item, index) => {
              return item.isTotal ? '合计' : (index + 1)
            },
          },
          {
            title: '结算账户',
            dataIndex: 'account_name',
            key: 'account_name',
            width: 200,
          },
          {
            title: '付款金额',
            dataIndex: 'collection_amount',
            key: 'collection_amount',
            width: 200,
          }
        ],
      }
    },
    created(){
      this.initData();
    },
    methods: {
      getJsBarcode(number) {
        JsBarcode("#barcode", number, {
          lineColor: '#000',
          width: 2,
          height: 40,
          displayValue: true
        });
      },
      initData() {
        this.loading = true;
        saleOrderDetail({ id: this.$route.query.id }).then(data => {
          this.info = data;
          this.info.sales_account_items = [
            ...this.info.sales_account_items,
            {
              id: '-1',
              isTotal: true,
              collection_amount: this.info.collection_amount,
            },
          ];
          this.info.sales_goods_items = [
            ...this.info.sales_goods_items,
            {
              id: '-1',
              isTotal: true,
              sales_quantity: this.info.total_quantity,
              totalAmount: this.info.total_amount,
            },
          ];
          this.getJsBarcode(data.number)
        }).finally(() => {
          this.loading = false;
        });
      },
    },
    mounted() {
      this.initData();
    },
  }
</script>
<style lang="less">
	
	#printContent{
		/* font-family: "宋体"; */
		color: #000;
	}
	.apps{
		/* width: 900px; */
		margin: 0 auto;		
	}
	.top{
		
	}
	.title{
		text-align: center;
		height: 60px;
		line-height: 60px;
	}
	.apps .ant-table-bordered .ant-table-thead > tr > th{
		font-weight: 800;
	}
	.apps .ant-table-bordered .ant-table-thead > tr > th, .apps .ant-table-bordered .ant-table-tbody > tr > td{
		border-right:1px solid #000 !important;
		border-top: #000 1px solid !important;
		border-bottom: 1px solid #000 !important;
		border-left: 1px solid #000 !important;
		text-align: center !important;
		color: #000 !important;
	}
	.apps .ant-table-thead > tr > th, .apps .ant-table-tbody > tr > td{
		padding:5px;
	}
	.apps .ant-table-thead > tr > th{
		border: #000;
		text-align: center;
		background: none !important;
	}
	.apps .ant-table-body{
		table{
			border:1;
			border-color: #000 !important;
			border-collapse: collapse !important;
			font-size: 14px !important;
			text-align: center;
		}
		th{
			background-color: #fff !important;
			height: 20px !important;
		}
		tr{
			text-align: left;
			border-color: #000 !important;
			border-collapse: collapse !important;
			height: 20px !important;
		}
		td{
			text-align: left;
			border-color: #000 !important;
			border-collapse: collapse !important;
			height: 20px !important;
		}
	}
	.apps table{
		
		border-color: #000;
		border-collapse: collapse;
		
	}
	.apps tr{
		text-align: center;
		border-color: #000;
		border-collapse: collapse;
	}
	.apps td{
		text-align: center;
		border-color: #000;
		border-collapse: collapse;
	}
</style>
